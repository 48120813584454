<template>
  <div class="metrics-list">
    <h3>Choose your metric</h3>
    <div class="metrics-list__box">
      <div
        class="metrics-list__item"
        v-for="(item, index) of metricsArr"
        :key="item.ID"
        :class="{ active: chosenMetric === item, [item.COLOR]: !!item.COLOR, 'dev': item.dev && !devMode }"
        @click="chooseMetric(item)"
      >
        <div class="title">
          <div class="title__text">{{ item.TITLE }}</div>
          <div class="info" v-if="item.TIP">
            <icon name="info"></icon>
            <div class="tip">{{ item.TIP }}</div>
          </div>
          <div class="number">{{ index + 1 }}</div>
          <img class="image" :src="item.IMAGE" :alt="item.TITLE" v-if="item.IMAGE"/>
        </div>
      </div>
    </div>
    <div class="metrics-list__carousel">
      <VueSlickCarousel v-bind="settings" @afterChange="carouselChangeHandler">
        <div v-for="(item, index) of metricsArr" :key="item.ID" class="metrics-list__subitem">
          <div :class="{ 'metrics-list__item': true, active: chosenMetric === item }">
            <div class="item__title">
              <div class="title__text">{{ item.TITLE }}</div>
              <div class="number">{{ index + 1 }}</div>
              <img class="image" :src="item.IMAGE" :alt="item.TITLE" />
            </div>
          </div>
        </div>
      </VueSlickCarousel>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import { STEPS } from "@/constants";
import Icon from "@/components/Icon";

export default {
  name: "MetricsList",

  components: {
    VueSlickCarousel,
    Icon
  },
  mounted() {
    this.devMode = !!parseInt(localStorage.getItem('devMode'))
  },

  data() {
    return {
      STEPS: STEPS,
      METRICS: this.metrics,
      chosenMetric: this.metrics ? this.metrics[0] : undefined,
      devMode: false,
      settings: {
        dots: false,
        arrow: false,
        speed: 500,
        autoplay: false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2
            }
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1
            }
          }
        ],
        slidesToScroll: 2,
        centerMode: true,
        centerPadding: "40px",
        focusOnSelect: true
      },
      metricsArr: this.metrics
    };
  },

  props: {
    step: Number,
    metrics: Array
  },

  methods: {
    chooseMetric(metric) {
      if (this.step === this.STEPS.PROCESSING) {
        return;
      }
      this.chosenMetric = metric;
      this.$emit("choseMetric", metric);
    },

    carouselChangeHandler(id) {
      this.chosenMetric = this.metricsArr[id];
      this.$emit("choseMetric", this.chosenMetric);
    }
  }
};
</script>

<style lang="scss">
@import "../assets/variables.scss";

.metrics-list {
  width: 236px;
  margin-left: 124px;
  padding-top: 16px;

  h3 {
    text-transform: uppercase;
  }

  &__carousel {
    visibility: hidden;
  }

  .title__text {
    white-space: pre-line;
  }

  &__item {
    position: relative;
    z-index: 1;
    height: 65px;
    margin-bottom: 6px;
    padding: 12px 18px 0 18px;
    border-radius: 6px;
    background: #f3f2f2;
    transition-duration: 0.25s;
    transition-property: color, transform, box-shadow;

    .info {
      position: absolute;
      right: 15px;
      top: 15px;
      width: 13px;
      height: 13px;
      border-radius: 50%;
      z-index: 1;

      svg {
        display: block;
        width: 13px;
        height: 13px;
        fill: #e1dede;
        transition: 0.25s;
      }

      .tip {
        position: absolute;
        left: 24px;
        top: -10px;
        z-index: 2;
        display: none;
        width: 150px;
        padding: 12px;
        background-color: #ffffff;
        box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.08);
        border-radius: 5px;
        color: #303133;

        &:before {
          position: absolute;
          top: 6px;
          left: -19px;
          display: block;
          width: 0;
          height: 0;
          border: 10px solid transparent;
          border-right: 10px solid #ffffff;
          content: "";
        }
      }

      &:hover {
        svg {
          fill: #6bc53d;
        }

        .tip {
          display: block;
        }
      }
    }

    .image {
      pointer-events: none;
      position: absolute;
      width: 75px;
      right: 0;
      bottom: 0;
    }

    .number {
      position: absolute;
      left: 19px;
      bottom: 3px;
      opacity: 0.03;
      font-size: 40px;
      line-height: 2;
    }

    &.blue,
    &.green,
    &.orange,
    &.red {
      &::before,
      &::after {
        --indent: 0;
        content: "";
        position: absolute;
        left: var(--indent);
        right: var(--indent);
        top: var(--indent);
        bottom: var(--indent);
        border-radius: 6px;
        z-index: -2;
      }

      &::after {
        --indent: 2px;
        background: #f3f2f2;
        transition: background 0.25s;
      }
    }

    &.blue {
      &::before {
        @include blue-gradient();
      }
    }

    &.green {
      &::before {
        @include green-gradient();
      }
    }

    &.orange {
      &::before {
        @include yellow-gradient();
      }
    }

    &.red {
      &::before {
        @include red-gradient();
      }
    }

    &.dev {
      display: none;
    }

    &:hover {
      box-shadow: 0 0 5px 7px rgba(0, 0, 0, 0.025);
      cursor: pointer;
    }

    &.active {
      @include green-gradient();
      transform: scale(1.02);
      box-shadow: 0 0 5px 7px rgba(0, 0, 0, 0.05);

      &::after {
        background: transparent;
      }

      .info {
        svg {
          fill: rgba(255, 255, 255, 0.3);
        }
      }

      .title,
      .number {
        color: #fff;
      }

      .number {
        opacity: 0.1;
      }
    }
  }
}

@media (max-width: $breakpoint-laptop) {
  .metrics-list {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

@media (max-width: $breakpoint-tablet) {
  .metrics-list {
    margin-bottom: 35px;

    &__box {
      display: flex;
      justify-content: space-between;
    }

    &__item {
      width: 180px;
    }
  }
}

@media (max-width: $breakpoint-tablet) {
  .metrics-list {
    margin-bottom: 20px;

    &__box {
      display: none;
    }

    &__carousel {
      visibility: visible;
    }

    &__item {
      box-sizing: border-box;
      width: 90%;
      margin-left: 5%;
      margin-bottom: 0;
      height: 70px;
      padding-top: 0;

      .item__title {
        display: flex;
        align-items: center;
        height: 70px;
      }

      &.active {
        transform: scale(1.08);
        box-shadow: 0 0 7px 7px rgba(0, 0, 0, 0.03);

        .item__title {
          color: #fff;
        }
      }

      .title__text {
        font-size: 14px;
      }

      .image {
        position: absolute;
        right: 0;
        bottom: 0;
        width: auto;
        height: 64px;
        z-index: -1;
      }

      .number {
        display: none;
      }
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  .metrics-list {
    &__subitem {
      margin: 14px 0;
    }
  }
}
</style>
