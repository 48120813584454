<template>
  <div class="progress-box" ref="overlay">
    <el-progress
      :percentage="progress ? progress : 0"
      :stroke-width="8"
      :text-inside="true"
      :format="formatProgressText"
      :color="'rgb(105, 196, 60)'"
      v-bind:style="{ marginTop: progressBoxHeight / 2 - 20 + 'px' }"
    ></el-progress>
    <div class="progress-desc">
      <div class="progress-desc__item">
        {{ progress !== null ? "Progress:" : "Video is in the queue" }}
      </div>
      <div class="progress-desc__item">
        {{ progress !== null ? progress + "%" : null }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProcessProgressBar",

  props: {
    progress: Number,
    sourceVideoHeight: Number
  },

  data() {
    return {
      progressBoxHeight: 0
    };
  },

  mounted() {
    this.progressBoxHeight = this.$refs.overlay.offsetHeight;
    window.addEventListener("resize", this.handleResize);
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },

  methods: {
    /**
     * Format progress text as empty value.
     */
    formatProgressText() {},

    handleResize() {
      this.progressBoxHeight = this.$refs.overlay.offsetHeight;
    }
  }
};
</script>

<style scoped lang="scss">
@import "../assets/variables.scss";

.progress-desc {
  display: flex;
  justify-content: space-between;
  padding: 10px 7.5% 0;
  color: #fff;
}

.el-progress {
  padding: 0 7.5%;
}
</style>
