<template>
  <div id="widget-app">
    <Home
      :backend-url="backendUrl"
      :socketUrl="socketUrl"
      :field-type="videoFieldtype"
      :groups="groups"
      :metricsNames="(metrics || '').split(',')"
      :default-tool-url="defaultToolUrl"
      :video-id="videoId"
      :video-link="videoLink"
      :healthcheck="healthcheck"
    />
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import "./plugins/element.js";
import VueVideoPlayer from "vue-video-player";
import "video.js/dist/video-js.css";

import Home from "./views/Home";
import { Notification } from "element-ui";
import { METRICS, notificationDuration } from "@/constants";
import ERROR_MESSAGES from "@/constants/error-messages";

Vue.config.productionTip = false;

Vue.use(VueVideoPlayer);
Vue.use(VueAxios, axios);

export default {
  name: "app",

  components: { Home },

  props: {
    backendUrl: String,
    socketUrl: String,
    defaultToolUrl: String,
    metricsOptions: Object,
    videoFieldtype: String,
    videoId: String,
    videoLink: String,
    metrics: String,
    groups: String, // comma-separated groups to filer metrics
    healthcheck: Boolean,
  },

  methods: {
    handleHttpError() {
      axios.interceptors.response.use(null, error => {
        const apiMessage = error.response && error.response.data && error.response.data.message;
        Notification({
          type: "error",
          title:
            ERROR_MESSAGES[apiMessage] ||
            ERROR_MESSAGES[error.message] ||
            apiMessage ||
            error.message ||
            ERROR_MESSAGES["default"],
          duration: notificationDuration
        });

        return Promise.reject(error);
      });
    }
  },

  mounted() {
    this.handleHttpError();
  },

  created() {
    for (const key in this.metricsOptions || {}) {
      if (METRICS.hasOwnProperty(key)) {
        METRICS[key].setEnvBasedValues(this.metricsOptions[key]);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./assets/variables.scss";

#widget-app {
  max-width: 1820px;
  margin: 30px auto 100px;
  font-size: 16px;
  font-family: $fonts;
  line-height: 1.4;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $medium-grey;
}

@media (max-width: $breakpoint-tablet) {
  #widget-app {
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
}
</style>
