<template>
  <div class="buttons-bar">
    <a
      v-if="step === STEPS.PROCESSED && processedVideoDownloadLink"
      :href="processedVideoDownloadLink"
      class="buttons-bar__download"
      download=""
      target="_blank"
    >
      <el-button icon="el-icon-download" type="success">Download selected fragment</el-button>
    </a>
    <a
      v-if="step === STEPS.PROCESSED && processedFullVideoDownloadLink"
      :href="processedFullVideoDownloadLink"
      class="buttons-bar__download"
      download=""
      target="_blank"
    >
      <el-button icon="el-icon-download" type="success">Download full video</el-button>
    </a>

    <el-button
      v-if="step === STEPS.SELECTING || step === STEPS.SECOND_SELECTING || step === STEPS.SELECTED"
      :disabled="(step === STEPS.SELECTING || step === STEPS.SECOND_SELECTING) && isTwoObjectsMetric"
      class="buttons-bar__start"
      icon="el-icon-caret-right"
      type="success"
      @click="startTracking"
    >
      Start Tracking
    </el-button>

    <el-button
      v-if="step === STEPS.STARTED"
      class="buttons-bar__stop"
      icon="el-icon-caret-right"
      type="danger"
      @click="stopTracking"
    >
      Stop Tracking
    </el-button>

    <el-button
      v-if="step === STEPS.STOPPED && !metric.autoselect"
      class="buttons-bar__reset"
      icon="el-icon-refresh-right"
      type="warning"
      @click="resetTracking"
    >
      Reset
    </el-button>

    <el-button
      v-if="step === STEPS.STOPPED"
      class="buttons-bar__measure"
      icon="el-icon-check"
      type="success"
      @click="submitData"
    >
      Measure
    </el-button>

    <el-button
      v-if="step === STEPS.PROCESS_DATA_SENDING || step === STEPS.PROCESSING"
      :disabled="step === STEPS.PROCESS_DATA_SENDING"
      class="buttons-bar__cancel"
      icon="el-icon-close"
      type="danger"
      @click="cancelSubmitData"
    >
      cancel
    </el-button>

    <el-button
      v-if="step === STEPS.PROCESSED"
      class="buttons-bar__again"
      icon="el-icon-refresh-right"
      type="success"
      @click="resetTracking"
    >
      Try video again
    </el-button>
  </div>
</template>

<script>
import { STEPS, Metric } from "@/constants";

export default {
  name: "ButtonsBar",

  props: {
    step: Number,
    processErrorMessage: String,
    processedVideoDownloadLink: String,
    processedFullVideoDownloadLink: String,
    topPosition: Boolean,
    metric: Metric
  },

  data() {
    return {
      STEPS: STEPS
    };
  },

  computed: {
    isTwoObjectsMetric() {
      return Boolean(this.metric?.isTwoObjectsMetric);
    }
  },

  methods: {
    /**
     * Emit start tracking event to parent component
     */
    startTracking() {
      this.$emit("startTracking");
    },

    /**
     * Emit stop tracking event to parent component
     */
    stopTracking() {
      this.$emit("stopTracking");
    },

    /**
     * Emit reset tracking event to parent component
     */
    resetTracking() {
      this.$emit("resetTracking");
    },

    /**
     * Emit submitting data event to parent component
     */
    submitData() {
      this.$emit("submitData");
    },

    /**
     * Emit caneling submitting data event to parent component
     */
    cancelSubmitData() {
      this.$emit("cancelSubmitData");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/variables.scss";

.buttons-bar {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;

  .el-button {
    margin-left: 10px;
  }

  &__download {
    display: none;
  }

  button {
    margin-right: 20px;
  }

  &.mobile-buttons {
    .el-button {
      display: none;
    }

    .buttons-bar__download {
      display: inline-block;

      .el-button {
        display: inline-block;
        margin-left: 0;
      }
    }
  }

  .stop-button {
    /deep/ .el-icon-caret-right {
      transform: scale(0.8);

      &:before {
        display: block;
        background: #fff;
        border-radius: 3px;
      }
    }
  }

  /deep/ .submit-buton {
    .el-icon-caret-right:before {
      display: inline-block;
      width: 15px;
      height: 16px;
      background: url("../assets/download.svg");
      background-size: cover;
      content: "";
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  .buttons-bar {
    text-align: center;

    &.mobile-buttons {
      .buttons-bar__download .el-button,
      .el-button {
        display: inline-block;
        margin: 0 5px 10px 5px;
      }
    }
  }
}
</style>
