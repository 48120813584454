import firstImage from "../assets/1_m.png";
import secondImage from "../assets/2_m.png";
import thirdImage from "../assets/3_m.png";

export const maxFileSize = 200 * 1024 * 1024; // limit is 200 MB

/**
 * Enum with state values. Tool must be only in one of this state value.
 * @enum {number}
 */
export const STEPS = {
  NOT_WORKING: 0,
  CHOOSING_METRIC: 1,
  SELECTING: 2,
  SECOND_SELECTING: 3,
  SELECTED: 4,
  STARTED: 5,
  STOPPED: 6,
  PROCESS_DATA_SENDING: 7,
  PROCESSING: 8,
  PROCESSED: 9
};

// options for video player
export const PLAYER_OPTIONS = {
  controlBar: {
    fullscreenToggle: false
  },
  controls: true,
  fluid: true,
  autoplay: false,
  muted: true,
  language: "en",
  sources: [
    {
      type: null,
      src: null
    }
  ],
  inactivityTimeout: 0 // it makes control bar always visible
};

//
export const fieldTypes = {
  college: 0,
  high_school: 1,
  professional: 2
};

// duration showing notifications in ms
export const notificationDuration = 10000;

export const mp4MimeType = "video/mp4";

// only for development
export const CUSTOM_HEADERS = {
  "X-AUTH-TOKEN": "889a8d2befbdd7a936998ee79023969aefe4c3ec3806e757e36de3740c08ef13"
};

/**
 * @typedef {Object} metricOptions
 * @prop {string} metric
 * @prop {string} title
 * @prop {string} tip
 * @prop {string=} image
 * @prop {'blue'|'green'|'orange'|'red'=} color
 * @prop {boolean=} isTwoObjectsMetric
 * @prop {boolean=} autoselect
 * @prop {Array<'default'|'highlights'>=} groups
 * @prop {STEPS=} firstStep
 * @prop {boolean=} dev
 */
export class Metric {
  /**
   * @constructor
   * @param {metricOptions} options
   */
  constructor({
    metric,
    title,
    tip,
    image,
    color,
    isTwoObjectsMetric = false,
    autoselect = false,
    groups = ["default"],
    firstStep = STEPS.SELECTING,
    dev = false
  }) {
    this.METRIC = metric;
    this.TITLE = title;
    this.TIP = tip;
    this.IMAGE = image;
    this.COLOR = color;
    this.groups = groups;
    this.isTwoObjectsMetric = isTwoObjectsMetric;
    this.autoselect = autoselect;
    this.firstStep = firstStep;
    this.dev = dev;
    this.ID = Math.random()
      .toString(16)
      .replace("0.", "");
  }

  [Symbol.toPrimitive](hint) {
    switch (hint) {
      case "string":
        return this.METRIC;
      default:
        return this.METRIC;
    }
  }

  toString() {
    return this.METRIC;
  }

  setEnvBasedValues({ url }) {
    this.URL = url;
  }
}

class HighlightMetric extends Metric {
  /**
   * @constructor
   * @param {metricOptions} config
   */
  constructor(config) {
    /**
     * @const
     * @type {metricOptions}
     */
    const defaults = {
      groups: "highlights",
      firstStep: STEPS.STOPPED,
      autoselect: true
    };

    super({ ...config, ...defaults });
  }
}

export const METRICS = Object.freeze({
  MAX_SPEED_V2: new Metric({
    metric: "max_speed",
    title: "[V2] Max Speed &\nTime to Max Speed",
    tip: "Tip text max speed",
    image: firstImage,
    color: "blue" // green, orange, red, blue
  }),
  MAX_SPEED_ONLY_V2: new Metric({
    metric: "max_speed_only",
    title: "[V2] Max Speed",
    tip: "Tip text max speed",
    image: firstImage,
    color: "blue"
  }),
  YARDS_OF_SEPARATION_V2: new Metric({
    metric: "yards_of_separation",
    title: "[V2] Separation &\nClosing Speed",
    tip: "Tip text yards of separation",
    image: thirdImage,
    color: "blue", // green, orange, red, blue
    isTwoObjectsMetric: true
  }),
  MAX_SPEED_V2_VNN: new Metric({
    metric: "max_speed_vnn",
    title: "[Richardson] Max Speed \n& Time to Max Speed",
    tip: "Tip text max speed",
    image: null,
    color: "orange"
  }),
  MAX_SPEED_V3: new Metric({
    metric: "max_speed_v3",
    title: "[V3] Max Speed &\nTime to Max Speed",
    tip: "Tip text max speed",
    image: firstImage,
    color: "red", // green, orange, red, blue
    // dev: true
  }),
  MAX_SPEED_ONLY_V3: new Metric({
    metric: "max_speed_only_v3",
    title: "[V3] Max Speed",
    tip: "Tip text max speed",
    image: firstImage,
    color: "red", // green, orange, red, blue
    // dev: true
  }),
  YARDS_OF_SEPARATION_V3: new Metric({
    metric: "yards_of_separation_v3",
    title: "[V3] Separation &\nClosing Speed",
    tip: "Tip text yards of separation",
    image: thirdImage,
    color: "red", // green, orange, red, blue
    isTwoObjectsMetric: true,
    // dev: true
  }),
  MAX_SPEED: new Metric({
    metric: "max_speed",
    title: "Max Speed &\nTime to Max Speed",
    tip: "Tip text max speed",
    image: firstImage
  }),
  MAX_SPEED_ONLY: new Metric({
    metric: "max_speed_only",
    title: "Max Speed",
    tip: "Tip text max speed",
    image: firstImage
  }),
  TRANSITION_TIME: new Metric({
    metric: "transition_time",
    title: "Transition Time",
    tip: "Tip text transition time",
    image: secondImage
  }),
  YARDS_OF_SEPARATION: new Metric({
    metric: "yards_of_separation",
    title: "Separation &\nClosing Speed",
    tip: "Tip text yards of separation",
    image: thirdImage
  }),
  YARDS_AFTER_CATCH: new Metric({
    metric: "yards_after_catch",
    title: "Yards after &\ncatch",
    tip: "When the player catches the ball",
    image: firstImage
  }),
  TIME_BEHIND_LINE_OF_SCRIMMAGE: new Metric({
    metric: "time_behind_line_of_scrimmage",
    title: "Time to Line of\nScrimmage",
    tip: "Tip of Time to Line of Scrimmage",
    image: secondImage
  }),
  BLOCKING_TIME: new Metric({
    metric: "blocking_time",
    title: "Blocking Time",
    tip: "Tip for Blocking Time",
    image: thirdImage
  }),
  TIME_TO_SACK: new Metric({
    metric: "time_to_sack",
    title: "Time To Sack",
    tip: "Tip for Time To Sack",
    image: firstImage
  }),
  CLOSING_TIME_TO_BALL_CARRIER: new Metric({
    metric: "closing_time_to_ball_carrier",
    title: "Closing Time\nTo Ball Carrier",
    tip: "Tip for Closing Time To Ball Carrier",
    image: secondImage
  }),
  MULTIMETRIC_RB: new HighlightMetric({
    metric: "multimetric_rb",
    title: "Running Back\nHighlight",
    tip: "Calculate metrics for Running Back highlights video",
    image: secondImage
  }),
  MULTIMETRIC_WR: new HighlightMetric({
    metric: "multimetric_wr",
    title: "Wide Receiver\nHighlight",
    tip: "Calculate metrics for Wide Receiver highlights video",
    image: secondImage
  }),
  MULTIMETRIC_LB: new HighlightMetric({
    metric: "multimetric_lb",
    title: "Line Backer\nHighlight",
    tip: "Calculate metrics for Line Backer highlights video",
    image: secondImage
  }),
  MULTIMETRIC_EDGE: new HighlightMetric({
    metric: "multimetric_edge",
    title: "Edge\nHighlight",
    tip: "Calculate metrics for Edge highlights video",
    image: secondImage
  }),
  MULTIMETRIC_DB: new HighlightMetric({
    metric: "multimetric_db",
    title: "Defensive Back\nHighlight",
    tip: "Calculate metrics for Defensive Back highlights video",
    image: secondImage
  }),
});
