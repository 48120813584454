<template>
  <div class="video-editor">
    <el-row>
      <el-col :sm="8" :span="12" :xs="24" class="video-editor__timer-col">
        <div v-if="step === STEPS.STARTED || step === STEPS.STOPPED" :class="{ timer: true, negative: timerValue < 0 }">
          <div class="timer__desc">
            Time
            <br />
            tracker
          </div>
          <div class="timer__value">
            <span class="timer__value-number">{{ timerMinutesValue }}</span>
            <span class="timer__value-measure">m</span>
            <span class="timer__value-number">{{ timerSecondsValue }}</span>
            <span class="timer__value-measure">s</span>
          </div>
        </div>
      </el-col>
      <el-col :sm="16" :span="12" :xs="0" class="video-editor__buttons-col">
        <ButtonsBar
          :metric="metric"
          :processErrorMessage="processErrorMessage"
          :processedFullVideoDownloadLink="processedFullVideoDownloadLink"
          :processedVideoDownloadLink="processedVideoDownloadLink"
          :step="step"
          @cancelSubmitData="cancelSubmitData"
          @resetTracking="resetTracking"
          @startTracking="startTracking"
          @stopTracking="stopTracking"
          @submitData="submitData"
        />
      </el-col>
    </el-row>
    <div class="video-editor__col">
      <h3 class="video-editor__title">ACTIVE video</h3>
      <div ref="videoBox" class="video-editor__video">
        <video-player
          v-if="step"
          :options="getPlayerOptions()"
          :playsinline="true"
          @canplay="additionalCustomizePlayer"
          @loadeddata="customizePlayer"
          @play="checkSelection"
          @ready="additionalCustomizePlayer"
          @timeupdate="updateTimer"
        ></video-player>

        <div v-if="step < STEPS.SELECTING" class="overlay"></div>

        <AreaSelector
          v-if="isSelectionAreaVisible"
          :isSelectionStarted="isSelectionStarted"
          :sourceVideoHeight="sourceVideoHeight"
          :sourceVideoWidth="sourceVideoWidth"
          :step="step"
          class="overlay"
          v-on:selectionStart="selectionStarted"
          v-on:selectionUpdated="getCoords"
        />

        <AreaSelector
          v-if="step >= STEPS.SECOND_SELECTING && step <= STEPS.STOPPED && isTwoObjectsMetric"
          :isSelectionStarted="isSecondSelectionStarted"
          :sourceVideoHeight="sourceVideoHeight"
          :sourceVideoWidth="sourceVideoWidth"
          :step="step"
          class="overlay second-frame"
          v-on:selectionStart="secondSelectionStarted"
          v-on:selectionUpdated="getCoords"
        />

        <ProcessProgressBar
          v-if="step === STEPS.PROCESS_DATA_SENDING || step === STEPS.PROCESSING"
          :progress="videoProcessingProgress"
          class="overlay progress-box"
        />

        <ErrorResult
          v-if="step === STEPS.PROCESSED && !processedVideoUrl"
          :message="processErrorMessage"
          class="overlay error-display"
        />

        <div v-if="step === STEPS.NOT_WORKING" class="overlay not-working">
          <div class="text">
            <img alt="" src="../assets/helmet.svg" />
            <div>Tool is not working</div>
          </div>
        </div>
      </div>
      <Tips v-if="step !== STEPS.PROCESSED" :isTwoObjectsMetric="isTwoObjectsMetric" :step="step" />

      <VideoGallery :toolUrl="toolUrl" :videos="videoScenes" @selectSlide="setSelectedScene"></VideoGallery>

      <ButtonsBar
        :metric="metric"
        :processErrorMessage="processErrorMessage"
        :processedFullVideoDownloadLink="processedFullVideoDownloadLink"
        :processedVideoDownloadLink="processedVideoDownloadLink"
        :step="step"
        :topPosition="false"
        class="mobile-buttons"
        @cancelSubmitData="cancelSubmitData"
        @resetTracking="resetTracking"
        @startTracking="startTracking"
        @stopTracking="stopTracking"
        @submitData="submitData"
      />
    </div>
  </div>
</template>

<script>
import { Notification } from "element-ui";
import ButtonsBar from "./ButtonsBar";
import AreaSelector from "./AreaSelector";
import ProcessProgressBar from "./ProcessProgressBar";
import Tips from "@/components/Tips";
import ErrorResult from "@/components/ErrorResult";
import { STEPS, PLAYER_OPTIONS, mp4MimeType, notificationDuration, Metric } from "@/constants";
import VideoGallery from "@/components/VideoGallery";

export default {
  name: "VideoEditor",

  components: {
    ButtonsBar,
    AreaSelector,
    Tips,
    ProcessProgressBar,
    ErrorResult,
    VideoGallery
  },

  props: {
    toolUrl: String,
    videoUrl: String,
    step: Number,
    videoProcessingProgress: Number,
    processedVideoUrl: String,
    processErrorMessage: String,
    metric: Metric,
    videoScenes: Array
  },

  data() {
    return {
      STEPS: STEPS,
      player: null,
      playerOptions: PLAYER_OPTIONS,
      selectFieldValue: null,
      sourceVideoWidth: null,
      sourceVideoHeight: null,
      isSelectionStarted: false,
      isSecondSelectionStarted: false,
      startTime: null,
      endTime: null,
      timerValue: null,
      coords: {
        x1: null,
        y1: null,
        x2: null,
        y2: null
      },
      secondCoords: {
        x1: null,
        y1: null,
        x2: null,
        y2: null
      },
      selectedScene: null
    };
  },

  computed: {
    isTwoObjectsMetric() {
      return Boolean(this.metric?.isTwoObjectsMetric);
    },
    /**
     * Convert difference between start and current position in video.
     * @return {string} Differnce in minutes with 0 if it is need.
     */
    timerMinutesValue() {
      if (this.timerValue) {
        if (this.timerValue > 0) {
          return String(Math.floor(this.timerValue / 60)).padStart(2, "0");
        } else {
          return "-" + String(Math.ceil(this.timerValue / 60)).padStart(2, "0");
        }
      } else {
        return "00";
      }
    },

    /**
     * Convert difference between start and current position in video into readable view.
     * @return {string} Differnce in seconds with 0 if it is need.
     */
    timerSecondsValue() {
      return this.timerValue ? String(Math.abs(this.timerValue) % 60).padStart(2, "0") : "00";
    },

    /**
     * Create link for downloading processed video scene.
     */
    processedVideoDownloadLink: function() {
      if (this.selectedScene) {
        if (this.selectedScene.url?.startWith("http")) {
          return this.selectedScene.url;
        } else {
          return `${this.toolUrl}${this.selectedScene.url}?download=1`;
        }
      } else {
        return "";
      }
    },

    /**
     * Create link for downloading processed full video.
     */
    processedFullVideoDownloadLink: function() {
      if (this.processedVideoUrl?.startsWith("http")) {
        return this.processedVideoUrl;
      } else {
        return this.processedVideoUrl && `${this.toolUrl}${this.processedVideoUrl}?download=1`;
      }
    },

    isSelectionAreaVisible() {
      const hasExceptionGroup = this.metric.groups && ["highlights"].some(group => this.metric.groups.includes(group));
      return !hasExceptionGroup && this.step >= this.STEPS.SELECTING && this.step <= this.STEPS.STOPPED;
    }
  },

  mounted() {
    const _this = this;
    this.$root.$on("changeMetric", () => {
      _this.resetTracking();
    });
  },

  methods: {
    /**
     * Combine player options from constants and got props
     * @return {Object} Players options
     */
    getPlayerOptions() {
      let processedUrl = this.processedVideoUrl;
      if (!this.processedVideoUrl?.startsWith("http")) {
        processedUrl = this.toolUrl + this.processedVideoUrl;
      }
      this.playerOptions.sources[0].src = this.processedVideoUrl ? processedUrl : this.videoUrl;
      this.playerOptions.sources[0].type = mp4MimeType;
      return this.playerOptions;
    },

    /**
     * In iPhone event 'loadeddata' doesn't happen after video is loaded and this function creates it.
     * Also some issues with player may be on hard page reloading.
     * @param {Object} player
     */
    additionalCustomizePlayer(player) {
      setTimeout(function() {
        const playPromise = player.play();

        playPromise.then(() => {
          player.pause();
        });
      }, 0);
    },

    /**
     * Set some player options as data values in this component.
     * Play and pause video is necessary for showing first frame of video and controls bar.
     * Reset tracking params is necessary when video reloads before sending to server
     * @param {Object} player
     */
    customizePlayer(player) {
      this.player = player;
      player.play();
      player.pause();

      this.sourceVideoWidth = player.tech_.el_.videoWidth;
      this.sourceVideoHeight = player.tech_.el_.videoHeight;

      //this.resetTracking();
      this.endTime = Math.round(this.player.duration());
    },

    /**
     * Start selecting object in this video.
     * Video player should be on pause for user comfort.
     */
    selectionStarted() {
      this.isSelectionStarted = true;
      if (this.player) {
        this.player.pause();
      }
    },

    secondSelectionStarted() {
      this.isSecondSelectionStarted = true;
      if (this.player) {
        this.player.pause();
      }
    },

    /**
     * Finishing selection one or more objects in this video.
     * @param {Object} x1 x coordinate of left top corner of selecting frame
     * @param {Object} y1 y coordinate of left top corner of selecting frame
     * @param {Object} x2 x coordinate of right bottom corner of selecting frame
     * @param {Object} y2 y coordinate of right bottom corner of selecting frame
     */
    getCoords(x1, y1, x2, y2) {
      if (this.isTwoObjectsMetric) {
        if (
          this.step === this.STEPS.SECOND_SELECTING ||
          (this.step === this.STEPS.SELECTED && this.isTwoObjectsMetric)
        ) {
          this.$emit("selectingFinished");
          this.secondCoords.x1 = x1;
          this.secondCoords.y1 = y1;
          this.secondCoords.x2 = x2;
          this.secondCoords.y2 = y2;
        } else {
          this.$emit("firstSelectingFinished");
          this.coords.x1 = x1;
          this.coords.y1 = y1;
          this.coords.x2 = x2;
          this.coords.y2 = y2;
        }
      } else {
        this.$emit("selectingFinished");
        this.coords.x1 = x1;
        this.coords.y1 = y1;
        this.coords.x2 = x2;
        this.coords.y2 = y2;
      }
    },

    /**
     * Check if objects on video is selected.
     */
    checkSelection() {
      if (this.step === this.STEPS.SELECTED || this.step === this.STEPS.SECOND_SELECTING) {
        this.resetTracking();
      }
    },

    /**
     * Start tracking objects on video and record current time as start point.
     */
    startTracking() {
      this.$emit("trackingStarted");
      this.startTime = this.player.currentTime();
      this.player.play();
    },

    /**
     * Show notification if position of video progress bar is changed by user and it less than time of start point.
     */
    updateTimer() {
      if (!this.player) {
        return;
      }

      if (
        this.step !== this.STEPS.PROCESSED &&
        this.timerValue > 0 &&
        this.player &&
        this.player.currentTime() - this.startTime < 0
      ) {
        Notification({
          type: "warning",
          title: "Please make sure to stop tracking when the tracking time is more than 0",
          duration: notificationDuration
        });
      }

      this.timerValue = Math.round(this.player.currentTime() - this.startTime);
    },

    /**
     * Stop tracking objects on video and set current video position as time of end point
     */
    stopTracking() {
      this.$emit("trackingStopped");
      this.player.pause();
      this.endTime = this.player.currentTime();
    },

    /**
     * Reset all tracked information. In this case tool should return to selecting step.
     */
    resetTracking() {
      this.$emit("cancelSelect");
      this.isSelectionStarted = false;
      this.startTime = null;
      this.endTime = null;
      this.timerValue = null;
      this.coords.x1 = this.coords.y1 = this.coords.x2 = this.coords.y2 = null;
      this.secondCoords.x1 = this.secondCoords.y1 = this.secondCoords.x2 = this.secondCoords.y2 = null;
    },

    /**
     * Submit tracked information.
     * If frame coordinates is absent or time of end point is more than time of start point,
     * information shouldn't be sent.
     */
    submitData() {
      if (!this.player) {
        return;
      }

      this.isSelectionStarted = false;

      if (this.coords.x1 === null && this.isTwoObjectsMetric) {
        Notification({
          type: "error",
          title: "Player is not circled",
          duration: notificationDuration
        });
        this.resetTracking();
        return;
      }

      const startTimePoint = this.startTime || 0;
      const endTimePoint = this.endTime ? this.endTime : this.player.duration();

      if (startTimePoint >= endTimePoint) {
        Notification({
          type: "error",
          title: "Please make sure that you stop tracking the player after you start tracking the player",
          duration: notificationDuration
        });
        this.resetTracking();
        return;
      }

      const boxes = [];

      if (
        typeof this.coords.x1 === "number" &&
        typeof this.coords.x2 === "number" &&
        typeof this.coords.y1 === "number" &&
        typeof this.coords.y2 === "number"
      ) {
        boxes.push([this.coords.x1, this.coords.y1, this.coords.x2, this.coords.y2]);
      }

      if (this.isTwoObjectsMetric) {
        boxes.push([this.secondCoords.x1, this.secondCoords.y1, this.secondCoords.x2, this.secondCoords.y2]);
      }

      this.$emit("submitVideo", {
        time: startTimePoint,
        boxes,
        time_limit: endTimePoint,
        is_highlight: this.metric.groups.includes("highlights")
      });
    },

    cancelSubmitData() {
      this.$emit("cancelSubmitVideo");
    },

    onChosenMetric(metric) {
      if (metric.autoselect) {
        this.stopTracking();
      }
    },

    setSelectedScene(scene) {
      this.selectedScene = scene && scene.url ? scene : null;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/variables.scss";

.video-editor {
  position: relative;
  padding: 0 50px;

  &__timer-col {
    min-height: 54px;

    .timer {
      display: inline-flex;
      width: 172px;
      padding: 7px 11px 7px 16px;
      box-sizing: border-box;
      border-radius: 4px;
      border: solid 1px #68c43b;
      color: #6cc63e;
      background-color: #f6fff1;
      transition: color 0.25s, border-color 0.25s, background-color 0.25s;

      &.negative {
        border-color: $error-red;
        color: $error-red;
        background-color: #ffe5e5;
      }

      &__desc {
        padding-right: 8px;
        text-align: left;
        font-size: 12px;
        line-height: 1;
      }

      &__value {
        &-measure {
          padding: 0 3px 0 7px;
          font-size: 22px;
          line-height: 1;
        }

        &-measure {
          opacity: 0.5;
          font-size: 17px;
          line-height: 22px;
        }
      }
    }
  }

  &__buttons-col {
    text-align: right;
  }

  &__title {
    margin-bottom: 2.5em;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
  }

  &__video {
    position: relative;
    margin-bottom: 20px;

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: calc(100% - 30px);

      &.second-frame {
        z-index: 2;
      }

      &.progress-box {
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 7px;
      }

      &.error-display {
        height: 100%;
        background-color: #ececec;
        border-radius: 7px;
      }

      &.not-working {
        opacity: 0.88;
        background-color: #f9f9f9;

        .text {
          padding-top: 420px;
          text-align: center;
          font-size: 16px;
          font-weight: 600;
          line-height: 1.5;
          color: #909399;
        }
      }
    }

    .video-player {
      padding-bottom: 30px;

      /deep/ .video-js {
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;

        .vjs-tech {
          border-top-right-radius: 10px;
          border-top-left-radius: 10px;

          &.vjs-error {
            overflow: hidden;
          }
        }

        .vjs-control-bar {
          @include green-gradient;
          bottom: -30px !important;
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
        }

        .vjs-load-progress,
        .vjs-load-progress div {
          background: rgba(255, 255, 255, 0.3);
        }

        .vjs-volume-panel {
          order: 2;

          &.vjs-volume-panel-horizontal:hover {
            width: 10em;
            transition: width 0.1s;
          }
        }

        &:hover .vjs-volume-control {
          visibility: visible;
          opacity: 1;
          position: relative;
          transition: visibility 0.1s, opacity 0.1s, height 0.1s, width 0.1s, left 0s, top 0s;

          &.vjs-volume-horizontal {
            width: 5em;
            height: 3em;
            margin-right: 0;
          }
        }
      }
    }
  }

  .error {
    &__header,
    &__text {
      color: $error-red;
    }
  }
}

@media (max-width: $breakpoint-laptop) {
  .content-overlay {
    .content-overlay__text {
      padding-top: 320px;
    }
  }
  .stop-user-tip__box {
    .user-tip__item {
      top: -64px;
      left: 183px;
      width: 105px;

      &::after {
        top: 47px;
        left: -13px;
        width: 44px;
        background: url(/img/arrow-left-bottom.bc19305b.svg) no-repeat;
      }
    }
  }
}

@media (max-width: $breakpoint-tablet) {
  .video-editor {
    padding: 0;
  }

  .content-overlay {
    .content-overlay__text {
      padding-top: 320px;
    }
  }

  .button-wrapper.submit-user-tip__box,
  .button-wrapper.submit-user-tip__box .el-button--success {
    width: 100%;
  }

  .user-tip__item {
    display: none;
  }

  .column-wrapper {
    padding-right: 0;
  }

  .align-left {
    min-height: 70px;
  }
}

@media (max-width: $breakpoint-mobile) {
  .video-editor {
    &__timer-col {
      min-height: 0;

      .timer {
        position: absolute;
        z-index: 1;
        right: 10px;
        top: 72px;
        width: 82px;
        height: 28px;
        padding: 2px 5px;

        &__desc {
          display: none;
        }

        &__value {
          &-measure {
            padding: 0 3px;
          }
        }
      }
    }

    &__buttons-col {
      display: none;
    }

    &__title {
      margin-bottom: 20px;
    }
  }

  .button-wrapper {
    display: block;
    margin-bottom: 20px;
    text-align: center;
  }

  .additional-button {
    display: inline-block;
  }

  .el-button {
    padding: 12px 16px;
    margin: 0 5px;
  }

  .align-left {
    position: relative;
    min-height: 0;
  }
}
</style>
