<template>
  <el-row class="home">
    <el-col :lg="metrics.length ? 6 : 3" :md="metrics.length ? 6 : 3" :sm="24" :xl="metrics.length ? 6 : 3">
      <a class="home__back-link" href="/library">
        <el-button class="camera-button" icon="el-icon-caret-right" type="warning">
          Back to library
        </el-button>
      </a>
      <MetricsList v-if="metrics.length" :metrics="metrics" :step="step" @choseMetric="goToFirstStep" />
    </el-col>
    <el-col
      :lg="{ span: metrics.length ? 17 : 17, offset: metrics.length ? 1 : 0 }"
      :md="18"
      :sm="24"
      :xl="{ span: metrics.length ? 17 : 17, offset: metrics.length ? 1 : 0 }"
    >
      <VideoEditor
        :metric="metric"
        :processErrorMessage="processErrorMessage"
        :processedVideoUrl="processedVideoUrl"
        :step="step"
        :toolUrl="toolUrl"
        :video-url="videoLink"
        :videoProcessingProgress="videoProcessingProgress"
        :videoScenes="processedScenes"
        @cancelSelect="goToFirstStep"
        @cancelSubmitVideo="cancelSubmitVideo"
        @firstSelectingFinished="goToSecondSelectingStep"
        @selectingFinished="goToSelectedStep"
        @submitVideo="submitVideo"
        @trackingStarted="goToStartStep"
        @trackingStopped="goToStopStep"
      />
    </el-col>
  </el-row>
</template>

<script>
import { METRICS, STEPS, fieldTypes, notificationDuration } from "@/constants";
import MetricsList from "@/components/MetricsList";
import VideoEditor from "@/components/VideoEditor";
import { Notification } from "element-ui";
import * as io from "socket.io-client";

export default {
  name: "home",

  components: {
    MetricsList,
    VideoEditor
  },

  props: {
    backendUrl: String,
    socketUrl: String,
    defaultToolUrl: String,
    fieldType: String,
    videoId: String,
    videoLink: String,
    groups: String,
    metricsNames: Array,
    healthcheck: {
      type: Boolean,
      default: true
    }
  },

  data() {
    let metrics = Object.values(METRICS);
    let groups = this.groups || "default";
    groups = groups.split(",").map(t => t.trim());
    metrics = metrics.filter(metric => groups.reduce((res, grp) => res || metric.groups.indexOf(grp) !== -1, false));
    const filteredMetrics = metrics.filter(item => this.metricsNames.includes(item.METRIC));
    metrics = filteredMetrics.length ? filteredMetrics : metrics;
    const defaultMetric = metrics[0];
    this.changeMetric(defaultMetric);

    return {
      step: defaultMetric.firstStep,
      STEPS: STEPS,
      metric: defaultMetric,
      metrics: metrics,
      sid: null,
      videoProcessingProgress: null,
      processKey: null,
      processedVideoUrl: "",
      processedScenes: [],
      processErrorMessage: "",
      stepBeforeUnavailability: null,
      isCancelSending: false,
      socketInstance: null
    };
  },

  mounted() {
    if (this.healthcheck) {
      this.checkPossibility();
      setInterval(() => {
        this.checkPossibility();
      }, 10000);
    }
    this.WSConnect();
  },

  computed: {
    toolUrl() {
      return this.metric.URL || this.defaultToolUrl;
    }
  },

  methods: {
    changeMetric(metric) {
      const oldToolUrl = this.toolUrl;
      this.metric = metric;

      if (this.toolUrl !== oldToolUrl) {
        this.WSConnect();
      }
    },
    /**
     * Checking if tool back-end can process video.
     */
    checkPossibility() {
      // If video is processing now possibility of processing new video doesn't matter
      if (this.step === this.STEPS.PROCESS_DATA_SENDING || this.step === this.STEPS.PROCESSING) {
        return;
      }

      this.axios
        .get(`${this.toolUrl}/can_process`)
        .then(response => {
          if (response.data.status === true) {
            if (this.step === this.STEPS.NOT_WORKING) {
              // Save step and inputted data by user
              this.step = this.stepBeforeUnavailability;
            }
          } else {
            throw new Error();
          }
        })
        .catch(error => {
          if (!error.request && this.step !== this.STEPS.NOT_WORKING) {
            this.stepBeforeUnavailability = this.step;
            this.step = this.STEPS.NOT_WORKING;
            showNotification(
              "error",
              "Sorry, the tool is temporarily down for maintenance. Please give us several minutes and try again.",
              0
            );
          }
        });
    },

    /**
     * Change step of application to selecting as start point.
     * Processed data should be reset in any case.
     */
    goToFirstStep(metric) {
      if (metric) {
        const oldUrl = this.toolUrl;
        this.changeMetric(metric);
        this.$root.$emit("changeMetric", metric);

        if (this.toolUrl !== oldUrl) {
          this.checkPossibility();
        }
      }

      this.step = this.metric.firstStep;

      this.isCancelSending = false;
      this.videoProcessingProgress = null;
      this.processKey = null;
      this.processedVideoUrl = "";
      this.processErrorMessage = "";
    },

    /**
     * Change step of application to selecting second object in some metrics.
     */
    goToSecondSelectingStep() {
      this.step = this.STEPS.SECOND_SELECTING;
    },

    /**
     * Change step of application to selected
     */
    goToSelectedStep() {
      this.step = this.STEPS.SELECTED;
    },

    /**
     * Change step of application to start tracking objects in video
     */
    goToStartStep() {
      this.step = this.STEPS.STARTED;
    },

    /**
     * Change step of application to stop tracking objects in video
     */
    goToStopStep() {
      this.step = this.STEPS.STOPPED;
    },

    /**
     * Submit data of selected and tracked objects in video to server.
     * @param {Object} data Data of selected and tracked objects.
     */
    submitVideo(data) {
      this.step = this.STEPS.PROCESS_DATA_SENDING;

      const formData = new FormData();

      for (let key in data) {
        if (key === "boxes") {
          formData.append(key, JSON.stringify(data["boxes"]));
          continue;
        }
        formData.append(key, data[key]);
      }
      formData.append("sid", this.sid);
      formData.append("field_type", fieldTypes[this.fieldType]);
      if (this.metrics.length) {
        formData.append("metric", this.metric);
      } else {
        formData.append("metric", "max_speed_only");
      }

      let promise = undefined
      if (this.backendUrl) {
        promise = this.__getProcessingSession(formData)
      } else {
        promise = this.__processing(formData)
      }

      promise.catch(error => {
        if (!error.request) {
          this.step = this.STEPS.NOT_WORKING;
          return;
        }
        let response = error.request && error.request.response ? JSON.parse(error.request.response) : null;
        this.goToFirstStep();
        showNotification(
          "error",
          response && response[0] && response[0].message ? response[0].message : error.message
        );
      });

    },

    __getProcessingSession(formData) {
      return this.axios
        .post(
          `${this.backendUrl === "/" ? "" : this.backendUrl}/api/processing-sessions`,
          {
            video: this.videoId
          } /* ,
          {
            headers: CUSTOM_HEADERS
          }*/
        )
        .then(response => {
          formData.append("id", response.data.id);
          formData.append("token", response.data.token);
          return this.__processing(formData);
        })
    },
    __processing(formData) {
      let handler = undefined
      if (window.RECRUITING_ANALYTICS_WIDGET_CONFIG.hasOwnProperty('processingHandler')) {
        handler = window.RECRUITING_ANALYTICS_WIDGET_CONFIG['processingHandler'](this.axios, this.toolUrl, formData)
      } else {
        handler = this.axios.post(`${this.toolUrl}/calc_metrics`, formData)
      }
      return handler
        .then(response => {
          this.processKey = response.data.key;
          this.step = this.STEPS.PROCESSING;
        });
    },

    /**
     * Cancel submitting data to server. In this case data should be resetted and tool should return to selecting state
     */
    cancelSubmitVideo() {
      if (this.isCancelSending) {
        return;
      }

      this.isCancelSending = true;

      this.axios
        .patch(`${this.toolUrl}/task/${this.processKey}/cancel`)
        .then(() => {
          this.goToFirstStep();
          Notification({
            type: "success",
            title: "Processing the video was cancelled",
            message:
              "If you would like to re-start it please re-select the player and the tracking period and submit the video again",
            duration: notificationDuration
          });
          this.checkPossibility();
        })
        .catch(() => {
          this.goToFirstStep();
        });
    },

    updateTask() {
      if (this.processKey && this.sid) {
        this.axios.patch(`${this.toolUrl}/task/${this.processKey}/sid`, { sid: this.sid }).catch(error => {
          if (!error.request) {
            return;
          }
          let response = error.request && error.request.response ? JSON.parse(error.request.response) : null;
          showNotification(
            "error",
            response && response[0] && response[0].message ? response[0].message : error.message
          );
        });
      }
    },

    subscribeToSocketEvents(instance) {
      const onConnect = onConnectHandler.bind(this);
      const onError = onErrorHandler.bind(this);
      const onConnectTimeout = onConnectTimeoutHandler.bind(this);
      const onMaxSpeed = onMaxSpeedHandler.bind(this);

      instance.on("connect", onConnect);
      instance.on("error", onError);
      instance.on("connect_timeout", onConnectTimeout);
      instance.on("max_speed", onMaxSpeed);
      instance.connect();
      /**
       * Socket connection
       */
      function onConnectHandler() {
        this.sid = this.socketInstance.id;
        this.updateTask();
      }

      /**
       * Socket error
       */
      function onErrorHandler(e) {
        window.console.error(e);
      }

      /**
       * Socket connection timeout
       */
      function onConnectTimeoutHandler() {
        showNotification("error", "Oops, something went wrong! Please try again");
      }

      /**
       * Handle socket event 'max speed'.
       * @param {Object} data Object with received data.
       */
      function onMaxSpeedHandler(data) {
        if (data.status === "error" || data.status === "completed") {
          this.step = this.STEPS.PROCESSED;
          this.videoProcessingProgress = null;
          this.processedVideoUrl = data.url;
          this.processedScenes = data.scenes ? Object.values(data.scenes).filter(item => item.url) : [];
          if (data.status === "error") {
            this.processErrorMessage = data.error;
            showNotification("error", data.error);
          }
          this.checkPossibility();
        } else {
          this.step = this.STEPS.PROCESSING;
          this.videoProcessingProgress = data.progress;
        }
      }
    },

    WSConnect() {
      const socketUrl = this.socketUrl ?? this.toolUrl;
      if (!socketUrl) {
        window.console.error("No socket url!");
        return;
      }

      let tempUrl = "";
      let protocol = "";
      let pathname = "";

      if (socketUrl.startsWith("https://") || socketUrl.startsWith("http://")) {
        const urlParts = socketUrl.split("://");
        protocol = urlParts[0];
        tempUrl = urlParts[1];
      }

      const tempUrlParts = tempUrl.split("/");
      pathname = tempUrlParts.filter((item, i) => item !== "" && i !== 0).join("/") + "/socket.io";

      if (!pathname.startsWith("/")) {
        pathname = "/" + pathname;
      }

      this.socketInstance?.disconnect?.();

      this.socketInstance = io.io(`${protocol}://${tempUrl}`, {
        path: pathname,
        autoConnect: false,
        transports: ["websocket"]
      });

      this.subscribeToSocketEvents(this.socketInstance);
    }
  }
};

function showNotification(type, title, duration) {
  Notification({
    type,
    title,
    duration: duration !== undefined ? duration : notificationDuration
  });
}
</script>

<style lang="scss" scoped>
@import "../assets/variables.scss";

.home {
  margin-bottom: 0;

  /deep/ .el-button {
    padding: 10px 20px 10px 14px;
    font-size: 12px;
    font-weight: 700;
    font-family: $fonts;
    text-transform: uppercase;
    border: 0;
    transition: 0.25s;
    cursor: pointer;

    &--warning {
      background: linear-gradient(to left, #f5cf70, #e7a23d);
    }

    i {
      font-size: 18px;
    }

    span {
      vertical-align: 3px;
    }
  }

  /deep/ h3 {
    font-size: 1.17em;
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: 700;
    line-height: 1.4;
  }

  &__back-link {
    display: inline-block;

    .camera-button {
      /deep/ .el-icon-caret-right:before {
        display: inline-block;
        width: 18px;
        height: 16px;
        background: url("../assets/camera.svg");
        content: "";
      }
    }
  }
}

@media (max-width: $breakpoint-tablet) {
  .home {
    &__back-link {
      margin-bottom: 10px;
    }

    /deep/ h3 {
      text-align: center;
      margin: 10px 0;
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  .home {
    &__back-link {
      display: none;
    }
  }
}
</style>
