<template>
  <div class="error-display" ref="overlay">
    <h3 class="error-header" :style="{ paddingTop: boxHeight / 2 - 120 + 'px' }">
      {{ message ? "ERROR" : "&emsp;" }}
    </h3>
    <div class="error-text">{{ message }}</div>
    <img class="sad-ball" src="../assets/sad-ball.svg" alt="" />
  </div>
</template>

<script>
export default {
  name: "ErrorResult",

  props: {
    message: String
  },

  data() {
    return {
      boxHeight: 0
    };
  },

  mounted() {
    this.boxHeight = this.$refs.overlay.offsetHeight;
  }
};
</script>

<style scoped lang="scss">
@import "../assets/variables.scss";

.error-header {
  color: $error-red;
}

.error-text {
  min-height: 98px;
  padding-left: 8%;
  padding-right: 8%;
  font-size: 16px;
  font-weight: 400;
  color: $error-red;
}

.error-display {
  text-align: center;
}

/deep/ .vjs-control-bar {
  bottom: -30px !important;
}

.button-wrapper {
  padding-top: 20px;
  text-align: right;
}

.progress-box {
  padding-top: 260px;

  .progress-desc {
    display: flex;
    justify-content: space-between;
  }
}

@media (max-width: 1600px) {
  .progress-box {
    padding-top: 160px;
  }

  .error-text {
    min-height: 121px;
  }
}

@media (max-width: $breakpoint-laptop) {
  .progress-box {
    padding-top: 180px;
  }

  .error-text {
    min-height: 165px;
  }
}

@media (max-width: $breakpoint-tablet) {
  .button-wrapper {
    padding-top: 0;
    text-align: left;
  }

  .progress-box {
    padding-top: 50px;
  }

  .error-header {
    min-height: 0;
    padding-top: 15px;
  }

  .error-text {
    min-height: 0;
    padding-bottom: 15px;
  }
}
</style>
