<template>
  <div class="tip-box">
    <h3>TIPS</h3>
    <div v-if="step === STEPS.CHOOSING_METRIC" class="tip-text">
      Choose your metric
    </div>
    <div v-if="(step === STEPS.SELECTING || step === STEPS.SELECTED) && isTwoObjectsMetric" class="tip-text">
      Play or rewind the video till the point when you want to start tracking the player. Select the player with a box.
      It is important that the player is fully inside the box. When you're happy with the selection, click/tap "Start
      tracking"
    </div>
    <div v-if="(step === STEPS.SELECTING || step === STEPS.SELECTED) && !isTwoObjectsMetric" class="tip-text">
      Play or rewind the video till the point when you want to start tracking the player. If the video includes the
      marking around the player you want to track, please click "Start tracking". If it does not, please outline the
      player on the video and press "Start tracking"
    </div>
    <div v-if="step === STEPS.SECOND_SELECTING" class="tip-text">
      Select second player
    </div>
    <div v-if="step === STEPS.STARTED" class="tip-text">
      Press "Stop tracking" when you want to stop measuring above.
      <br />
      The duration of the tracked timespan will appear in the timer below. We recommend not to process time spans longer
      than 15 seconds.
    </div>
    <div v-if="step === STEPS.STOPPED" class="tip-text">
      Press "Measure" to calculate or "Reset" to reselect the player.
      <br />
    </div>
    <div v-if="step === STEPS.PROCESS_DATA_SENDING || step === STEPS.PROCESSING" class="tip-text">
      Wait until the video has been processed.
    </div>
    <div v-if="step === STEPS.NOT_WORKING" class="tip-text">
      Tool is not working
    </div>
  </div>
</template>

<script>
import * as constants from "@/constants/";

export default {
  name: "Tips",
  props: {
    step: Number
  },
  computed: {
    isTwoObjectsMetric() {
      return Boolean(this.metric?.isTwoObjectsMetric);
    }
  },
  data() {
    return {
      STEPS: constants.STEPS
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/variables.scss";

.tip-box {
  h3 {
    margin-top: 30px;
    margin-bottom: 8px;
  }

  .tip-text {
    min-height: 116px;
    font-size: 16px;
    font-weight: 400;
    color: $cool-grey;

    .green {
      color: $kelly-green;
    }
  }
}

@media (max-width: 1600px) {
  .tip-box {
    .tip-text {
      min-height: 140px;
    }
  }
}

@media (max-width: $breakpoint-laptop) {
  .tip-box {
    .tip-text {
      min-height: 110px;
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  .tip-box {
    h3 {
      margin-bottom: 10px;
    }

    .tip-text {
      font-size: 14px;
      min-height: 100px;
      margin-bottom: 20px;
    }
  }
}

@media (max-width: 350px) {
  .tip-box {
    .tip-text {
      min-height: 110px;
    }
  }
}
</style>
