<template>
  <section v-if="videos.length" class="video-gallery">
    <VueSlickCarousel ref="carousel" v-bind="settings" @init="carouselInitialized">
      <div
        v-for="(item, index) of videos"
        :key="item.key"
        :class="{ 'video-gallery__item_active': activeSlideIndex === index }"
        class="video-gallery__item"
        @click="slideSelected(index)"
      >
        <span class="video-gallery__item-label">{{ getVideoLabel(item) }}</span>
        <div class="video-gallery__item-wrap">
          <video-player v-if="item.url" :options="getPlayerOptions(item.url)" :playsinline="true"></video-player>
          <div v-if="activeSlideIndex !== index" class="overlay"></div>
        </div>
      </div>
    </VueSlickCarousel>
  </section>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import { mp4MimeType, PLAYER_OPTIONS } from "@/constants";

export default {
  name: "VideoGallery",
  components: {
    VueSlickCarousel
  },
  props: {
    videos: Array,
    toolUrl: String
  },
  data() {
    return {
      settings: {
        dots: false,
        arrow: true,
        infinite: false,
        autoplay: false,
        focusOnSelect: false,
        speed: 500,
        slidesToScroll: 3,
        slidesToShow: 3,
        responsive: [
          {
            breakpoint: 900,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      },
      playerOptions: PLAYER_OPTIONS,
      activeSlideIndex: 0
    };
  },
  methods: {
    carouselInitialized() {
      this.slideSelected(0);
    },
    slideSelected(index) {
      this.activeSlideIndex = index;
      this.$emit("selectSlide", this.videos[this.activeSlideIndex]);

      if (this.$refs.carousel) {
        this.$refs.carousel.goTo(index);
      }
    },
    getFullUrl(path) {
      return `${this.toolUrl}${path}?download=1`;
    },
    getPlayerOptions(path) {
      return {
        ...this.playerOptions,
        sources: [
          {
            type: mp4MimeType,
            src: this.getFullUrl(path)
          }
        ]
      };
    },
    getVideoLabel(item) {
      return item.result && item.result.max_speed ? "Max speed: " + item.result.max_speed.toFixed(1) + " m/s" : "";
    }
  }
};
</script>

<style lang="scss">
@import "../assets/variables.scss";

.video-gallery {
  margin-bottom: 10px;

  &__item {
    position: relative;
    width: 98% !important;
    box-sizing: border-box;
    outline: none;
    padding-top: 20px;

    &_active .video-gallery__item-wrap {
      border: solid 3px $kelly-green;
    }
  }

  &__item-wrap {
    border-radius: 6px;
    overflow: hidden;
  }

  &__item-label {
    position: absolute;
    top: 0;
    font-size: 12px;
    font-weight: bold;
    line-height: 1;
    color: $kelly-green;
  }

  &__control {
  }

  .slick-track {
  }

  .slick-slider {
    padding: 0 26px;
  }

  .slick-prev {
    left: -16px;
  }

  .slick-next {
    right: -16px;
  }

  .slick-slide {
    &.slick-current {
    }

    .video-player {
    }

    .video-js {
      width: 100%;
    }

    .vjs-big-play-button {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border: none;
      background: none;
      font-size: 5em;
    }
  }

  .slick-prev,
  .slick-next {
    border: 18px solid transparent;
    border-right-color: $meat-yellow;
    width: 0;
    height: 0;
    z-index: 1;

    &::before {
      content: "";
    }

    &.slick-disabled {
      opacity: 0;
    }
  }

  .slick-next {
    border: 18px solid transparent;
    border-left-color: $meat-yellow;
  }

  .overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
</style>
