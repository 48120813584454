// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/camera.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".home[data-v-6005e1f4]{margin-bottom:0}.home[data-v-6005e1f4] .el-button{padding:10px 20px 10px 14px;font-size:12px;font-weight:700;font-family:Nunito,Nunito-Bold,Nunito Sans,Helvetica,Arial,sans-serif;text-transform:uppercase;border:0;-webkit-transition:.25s;transition:.25s;cursor:pointer}.home[data-v-6005e1f4] .el-button--warning{background:-webkit-gradient(linear,right top,left top,from(#f5cf70),to(#e7a23d));background:linear-gradient(270deg,#f5cf70,#e7a23d)}.home[data-v-6005e1f4] .el-button i{font-size:18px}.home[data-v-6005e1f4] .el-button span{vertical-align:3px}.home[data-v-6005e1f4] h3{font-size:1.17em;margin-top:20px;margin-bottom:20px;font-weight:700;line-height:1.4}.home__back-link[data-v-6005e1f4]{display:inline-block}.home__back-link .camera-button[data-v-6005e1f4] .el-icon-caret-right:before{display:inline-block;width:18px;height:16px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");content:\"\"}@media (max-width:768px){.home__back-link[data-v-6005e1f4]{margin-bottom:10px}.home[data-v-6005e1f4] h3{text-align:center;margin:10px 0}}@media (max-width:640px){.home__back-link[data-v-6005e1f4]{display:none}}", ""]);
// Exports
module.exports = exports;
