<template>
  <div
    :class="{
      'area-selector': true,
      'cross-cursor': step === STEPS.SELECTING || step === STEPS.SECOND_SELECTING || step === STEPS.SELECTED
    }"
    ref="overlay"
    @mousedown="startSelection"
    @touchstart="startSelection"
    @mousemove="continueSelection"
    @touchmove="continueSelection"
    @mouseup="finishSelection"
    @touchend="finishSelection"
    @mouseleave="forceFinishSelection"
    @touchcancel="forceFinishSelection"
  >
    <div
      class="area_selection"
      ref="areaSelection"
      v-if="
        (step === STEPS.SELECTING || step === STEPS.SECOND_SELECTING || step === STEPS.SELECTED) && isSelectionStarted
      "
      :style="{
        top: startPointArea.y1 + 'px',
        left: startPointArea.x1 + 'px',
        width: startPointArea.x2 - startPointArea.x1 + 'px',
        height: startPointArea.y2 - startPointArea.y1 + 'px'
      }"
    ></div>
  </div>
</template>

<script>
import * as constants from "@/constants/";

export default {
  name: "AreaSelector",

  props: {
    step: Number,
    isSelectionStarted: Boolean,
    sourceVideoWidth: Number,
    sourceVideoHeight: Number
  },

  data() {
    return {
      STEPS: constants.STEPS,
      selectionInProgress: false,
      borderWidth: 2,
      overlayWidth: null,
      overlayHeight: null,
      isXExpansion: null,
      isYExpansion: null,
      startPointArea: {
        x1: null,
        y1: null,
        x2: null,
        y2: null
      }
    };
  },

  computed: {
    /**
     * Calculate coefficient exchange between source video file width and overlay DOM element width
     * @return {number} coefficient
     */
    kWidth: function() {
      return this.sourceVideoWidth / this.$refs.overlay.offsetWidth;
    },

    /**
     * Calculate coefficient exchange between source video file height and overlay DOM element height
     * @return {number} coefficient
     */
    kHeight: function() {
      return this.sourceVideoHeight / this.$refs.overlay.offsetHeight;
    }
  },

  mounted() {
    const overlayPosition = this.$refs.overlay.getBoundingClientRect();
    this.overlayWidth = overlayPosition.width;
    this.overlayHeight = overlayPosition.height;

    window.addEventListener("resize", this.handleResize);
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },

  methods: {
    /**
     * Set start of selecting obejct on video.
     * @param {Object} event Mouse or touch event object
     */
    startSelection(event) {
      //
      if (this.step === this.STEPS.PROCESS_DATA_SENDING || this.step === this.STEPS.PROCESSING) {
        return;
      }

      this.$emit("selectionStart");
      this.selectionInProgress = false;

      if (event.type === "touchstart") {
        this.changeAreaSelection(
          event.touches[0].clientX - this.borderWidth,
          event.touches[0].clientY - this.borderWidth,
          event.touches[0].clientX - this.borderWidth * 2,
          event.touches[0].clientY - this.borderWidth * 2
        );
      } else {
        this.changeAreaSelection(
          event.clientX - this.borderWidth,
          event.clientY - this.borderWidth,
          event.clientX - this.borderWidth,
          event.clientY - this.borderWidth
        );
      }
    },

    /**
     * Charge coordinates of frame angles.
     * This function may be called with 4 arguments when selecting starts and with 2 arguments
     * when user changes position of frame corner.
     * @param {number} x1 x coordinate of left top corner of frame
     * @param {number} y1 y coordinate of left top corner of frame
     * @param {number} x2 x coordinate of right top corner of frame
     * @param {number} y2 y coordinate of right top corner of frame
     */
    changeAreaSelection(x1, y1, x2, y2) {
      const overlayPosition = this.$refs.overlay.getBoundingClientRect();

      let X1, Y1, X2, Y2;

      if (x1 === null && y1 === null) {
        X1 = this.startPointArea.x1;
        X2 = x2 - overlayPosition.left;

        if (X2 > X1 && X2 > this.startPointArea.x2) {
          this.isXExpansion = true;
        } else if (X2 < X1 && X2 < this.startPointArea.x2) {
          this.isXExpansion = false;
        }

        if (!this.isXExpansion) {
          X1 = x2 - overlayPosition.left;
          X2 = this.startPointArea.x2;
        }

        Y1 = this.startPointArea.y1;
        Y2 = y2 - overlayPosition.top;

        if (Y2 > Y1 && Y2 > this.startPointArea.y2) {
          this.isYExpansion = true;
        } else if (Y2 < Y1 && Y2 < this.startPointArea.y2) {
          this.isYExpansion = false;
        }

        if (!this.isYExpansion) {
          Y1 = y2 - overlayPosition.top;
          Y2 = this.startPointArea.y2;
        }
      } else {
        X1 = x1 - overlayPosition.left;
        Y1 = y1 - overlayPosition.top;
        X2 = x2 - overlayPosition.left;
        Y2 = y2 - overlayPosition.top;

        this.isXExpansion = X2 > X1;
        this.isYExpansion = Y2 > Y1;
      }

      this.selectionInProgress = !(X1 < 0 || Y1 < 0 || X2 > overlayPosition.width || Y2 > overlayPosition.height);

      X1 = X1 < 0 ? 0 : X1;
      Y1 = Y1 < 0 ? 0 : Y1;
      X2 = X2 > overlayPosition.width ? overlayPosition.width : X2;
      Y2 = Y2 > overlayPosition.height ? overlayPosition.height : Y2;

      this.startPointArea.x1 = X1;
      this.startPointArea.y1 = Y1;
      this.startPointArea.x2 = X2;
      this.startPointArea.y2 = Y2;
    },

    /**
     * Continue selecting object on video.
     * @param {Object} event Mouse or touch event object
     */
    continueSelection(event) {
      if (!this.selectionInProgress || this.step === constants.STEPS.PROCESSING) {
        return;
      }

      if (event.type === "touchmove") {
        event.preventDefault();
        this.changeAreaSelection(
          null,
          null,
          event.touches[0].clientX - this.borderWidth * 2,
          event.touches[0].clientY - this.borderWidth * 2
        );
      } else {
        this.changeAreaSelection(
          null,
          null,
          event.clientX - this.borderWidth * 2,
          event.clientY - this.borderWidth * 2
        );
      }
    },

    /**
     * Finish selecting object on video.
     */
    finishSelection() {
      this.selectionInProgress = false;
      this.$emit(
        "selectionUpdated",
        Math.round(this.startPointArea.x1 * this.kWidth),
        Math.round(this.startPointArea.y1 * this.kHeight),
        Math.round(this.startPointArea.x2 * this.kWidth),
        Math.round(this.startPointArea.y2 * this.kHeight)
      );
    },

    /**
     * Finish selecting object on video if coursor went out of target element
     */
    forceFinishSelection() {
      if (this.step === constants.STEPS.SELECTING || this.step === constants.STEPS.SECOND_SELECTING) {
        return;
      }

      this.selectionInProgress = false;
    },

    /**
     * Handler of window resize event. Video element may change it's size so coefficient
     * and frame position should be recalculated.
     */
    handleResize() {
      if (this.startPointArea.x1 === null) {
        return;
      }

      const overlayPosition = this.$refs.overlay.getBoundingClientRect();
      const resizeKWidth = overlayPosition.width / this.overlayWidth;
      const resizeKHeight = overlayPosition.height / this.overlayHeight;

      this.startPointArea.x1 *= resizeKWidth;
      this.startPointArea.x2 *= resizeKWidth;
      this.startPointArea.y1 *= resizeKHeight;
      this.startPointArea.y2 *= resizeKHeight;

      this.overlayWidth = overlayPosition.width;
      this.overlayHeight = overlayPosition.height;
    }
  }
};
</script>

<style scoped lang="scss">
@import "../assets/variables.scss";

.area-selector {
  &.cross-cursor {
    cursor: crosshair;
  }

  .area_selection {
    position: absolute;
    border: $kelly-green 2px solid;
    box-shadow: #333 2px 2px 2px, #333 -2px -2px 2px;
  }

  &.second-frame {
    .area_selection {
      border: #aa1fe1 2px solid;
    }
  }
}
</style>
