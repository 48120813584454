var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"overlay",class:{
    'area-selector': true,
    'cross-cursor': _vm.step === _vm.STEPS.SELECTING || _vm.step === _vm.STEPS.SECOND_SELECTING || _vm.step === _vm.STEPS.SELECTED
  },on:{"mousedown":_vm.startSelection,"touchstart":_vm.startSelection,"mousemove":_vm.continueSelection,"touchmove":_vm.continueSelection,"mouseup":_vm.finishSelection,"touchend":_vm.finishSelection,"mouseleave":_vm.forceFinishSelection,"touchcancel":_vm.forceFinishSelection}},[(
      (_vm.step === _vm.STEPS.SELECTING || _vm.step === _vm.STEPS.SECOND_SELECTING || _vm.step === _vm.STEPS.SELECTED) && _vm.isSelectionStarted
    )?_c('div',{ref:"areaSelection",staticClass:"area_selection",style:({
      top: _vm.startPointArea.y1 + 'px',
      left: _vm.startPointArea.x1 + 'px',
      width: _vm.startPointArea.x2 - _vm.startPointArea.x1 + 'px',
      height: _vm.startPointArea.y2 - _vm.startPointArea.y1 + 'px'
    })}):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }